html {
	@media (max-width: 29.3125rem) { // rem(421) + map-get($spacers, 4)
		font-size:calc(100vw / 29.3125);
	}
}

ol,
ul,
dl {
	padding-left:rem(40);
}

.btn.disabled,
.btn:disabled {
	cursor: not-allowed;
}

.figure {
	display:block;

	audio {
		width:100%;
	}
}