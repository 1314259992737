.site-header {
	padding:map-get($spacers, 4);
	border-bottom:$hr-border-width solid $hr-border-color;

	@include media-breakpoint-up(md) {
		display:flex;
		justify-content:space-between;
		align-items:center;
		padding:map-get($spacers, 5);
	}

	&__logo {
		width:rem(421);
		height:rem(52);
	}

	&__links {
		padding-top:rem(13);

		ul {
			display:block;
			margin:0px;
			padding:0px;
			list-style:none;

			li {
				display:inline-block;
				margin:0px;
				padding:0px;

				+ li {
					margin-left:map-get($spacers, 3);
				}
			}
		}
	}
}